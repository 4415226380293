import * as React from "react"
import { Component } from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import "../components/work.css"
import GridSystem from "../components/GridSystem"
import { motion, useScroll, useTransform } from "framer-motion"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"
import Modal from "../components/Modal"

const IndexPage = ({ data }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allWpWork(limit: 6) {
  //       edges {
  //         node {
  //           title
  //           slug
  //           uri
  //           featuredImage {
  //             node {
  //               mediaItemUrl
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  const { ref, inView } = useInView({
    threshold: 0.4,
  })

  const animation = useAnimation()

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 3,
          bounce: 0.3,
        },
      })
    }
    if (!inView) {
    }
  }, [inView])

  return (
    <Layout>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.6,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 1,
          },
        }}
        className="aspect-ratio"
      >
        <iframe
          className="homevideo"
          src="https://player.vimeo.com/video/504233451?autoplay=1&loop=1&autopause=0&muted=1"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </motion.div>
      <div ref={ref}>
        <motion.div className="grid-container">
          <GridSystem colCount={2} md={6}>
            {data.allWpWork.edges.map(({ node }) => (
              <motion.div
              // animate={animation}
              //initial={{ y: "100vh" }}
              //key={node.slug}
              //className={"post"}
              >
                <Link to={node.uri}>
                  <img
                    alt={node.title}
                    className="featured-image"
                    src={node.featuredImage.node.mediaItemUrl}
                  />

                  <h3 className="work-title-link">{node.title}</h3>
                </Link>
              </motion.div>
            ))}
          </GridSystem>
        </motion.div>
      </div>
      <Modal>
        <h1>Hello bello</h1>
      </Modal>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allWpWork(limit: 6) {
      edges {
        node {
          id
          title
          slug
          uri

          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`
