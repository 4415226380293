import React, { useState } from "react"

export default function Modal({ children }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  return (
    <>
      <button onClick={handleOpen}>Open Modal</button>
      {isOpen && (
        <div className="wx-modal-backdrop">
          <div className="wx-modal-popup">
            <div onClick={handleClose}>{children}</div>
            <button onClick={handleClose}>Close</button>
          </div>
        </div>
      )}
    </>
  )
}
